@font-face {
    font-family: Gilroy;
    src: url('./fonts/gilroy-regular.ttf');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    background-color: #F5F5F5;
}

body {
    font-family: 'Gilroy', sans-serif;
    padding: 0px 11px 0px 20px;
    background-color: #F5F5F5;
}

.comment-image {
    border-radius: 5px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

@media (max-width: 992px) {
    body {
        padding: 0;
    }

    .right-part {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    div.col-12 {
        padding-right: 0;
        padding-left: 0;
    }
}