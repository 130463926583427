@import '../../styles/variables.scss';

.header {
    background: #fff;
    z-index: 9999;
    /*  border-bottom: 2px solid #ededed; */
    padding: 22px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 993px) {
        border-bottom: none;
        height: 100%;
        flex-direction: column-reverse;
        padding: 10px;
        overflow: hidden;

        &-title {
            width: 100% !important;
            text-align: left;
            padding: 0px 12px 0 !important;

            img {
                display: none !important;
                margin-right: 20px;
            }
        }

        &-logo {
            font-weight: 300 !important;
            width: 100%;
            padding-top: 30px;
            justify-content: center;
            border-left: 0px !important;
            margin-bottom: 33px;

            div {
                h1 {
                    opacity: 0.5;
                    font-size: 14px;
                    font-weight: 300 !important;
                    color: #000 !important;
                    line-height: 20px;

                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    &-title {
        display: flex;
        align-items: center;
        padding: 0.5rem;

        img {
            display: none;
            margin-right: 20px;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;

            @media (max-width: 480px) {

                font-size: 12px;
                line-height: 16.8px;

            }
        }

    }

    &-logo {
        display: flex;
        align-items: center;
        padding-left: 14px;

        img {
            margin-right: 14px;
        }

        div {
            width: 400px;

            h1 {
                font-size: 12px;
                font-weight: 300;
                line-height: 14px;
                color: #000;
                opacity: 0.5;


                b {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 15px;
                    opacity: 1;
                    color: #000;
                }
            }
        }
    }
}