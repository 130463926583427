@import '../../styles/variables.scss';

.main-content {
    .video-container {
        margin-bottom: 20px;
        position: relative;
        width: 100%;
        height: calc(100vh - 280px);

        &.max {
            height: calc(100vh - 199px);
        }

        iframe {
            border-radius: 6px;

            body {
                color: #000;
            }

            @media (max-width: 993px) {
                border-radius: 0px;
            }
        }

        .ticker {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) -25%, #000000 100%);
            position: absolute;
            bottom: 0;
            margin-bottom: 0;
            height: 36px;
        }

        @media (max-width: 993px) {
            margin-bottom: 0;
        }

        @media (max-width: 993px) {
            overflow: hidden;
            position: relative;
            padding-bottom: 54%;
            padding-top: 30px;
            height: 0;

            &.max {
                height: 0;
            }

        }

    }

    .video-unavailable {
        border-radius: 5px;
        background: #ededed;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        position: absolute;
        z-index: 22;
        height: 100%;
        @media (max-width: 993px) {
            margin-top: -30px;
        }
    }

    button.p-button {
        width: 200px;
        font-size: 14px;
        font-weight: 600;
        background: #ededed;
        border: 1px solid var(--main-color);
        border-radius: 10px;
        cursor: pointer;
        box-shadow: none;
        color: #000;

        &:hover {
            border: 1px solid var(--main-color);
            color: #000;
            background: #ededed;
            box-shadow: 0px 0px 10px #ededed;
        }
    }

    /*     @media (max-width: 900px) {
        iframe {
            height: 60vh !important;
        }
    }

    @media (max-width: 640px) {
        iframe {
            height: 40vh !important;
        }
    } */

    @media (max-width: 993px) {
        iframe {
            position: absolute;
            width: 100%;
            height: 100% !important;
            left: 0;
            top: 0;
        }
    }

    .ticker {
        height: 40px;
        border-width: 0px 2px;
        border-style: solid;
        border-color: #ededed;
        margin-bottom: 10px;
        padding: 0 10px;
        font-size: 24px;
        color: var(--main-color);
        font-weight: 300;
        text-transform: uppercase;
        line-height: 40px;

        @media (max-width: 993px) {
            height: 60px;
        }
    }

    .content-info {
        &:last-child {
            text-align: right;
        }

        &-title {
            line-height: 43px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 5px;
        }

        &-data {
            line-height: 43px;
            color: var(--main-color);
            font-size: 36px;
            font-weight: 600;
        }

        @media (max-width: 993px) {
            &-title {
                font-size: 14px;
                line-height: 16.8px;
            }

            &-data {
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
}

.title-block {
    @media (max-width: 993px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

#youtube_frame .ytp-cued-thumbnail-overlay-image {
    background-size: 100% !important;
}

.locker{
    position: absolute;
    height: 60px;
    width: 100%;
    border-radius: 5px;
    &_bottom{
        margin-top: -60px;
        @media (max-width: 993px) {
            margin-top: -45px;
            z-index: 2;
            height: 45px;
        }
    }
    &_top{
        height: 100% !important;

        // button.p-button{
        //     display: none;
        // }
        z-index: 2;
        background: rgba(0, 0, 0, 0.9);      
        display: flex;
        align-content: center;
        justify-content: center;
        @media (max-width: 993px) {
            z-index: 2;
            top: 0;
        }
        @media (max-width: 535px) {
            z-index: 2;
            background: rgba(0, 0, 0, 0.9);      
            display: flex;
            align-content: center;
            button.p-button{
                display: block;
                border-width: 2px;
                border-radius: 20px;
                background: transparent;        
                color: #ededed;
                margin: auto;
            }
        }
    }
}

.banner{
    background-color: rgb(0, 0, 0);
    height: 100%;
    min-height: 225px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 993px) {
        position: absolute;
        top: 0;
    }
}

video.auto-video{
    height: 100%;
    border-radius: 5px;
    background-color: rgb(0, 0, 0);
    @media (max-width: 993px) {
        margin-top: -30px;
        height: auto
    }
}