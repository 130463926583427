.container {
    max-width: 1320px;
}

.title {
    font-weight: bold;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: var(--main-color)
}

.course {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.info {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.email {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: var(--main-color)
}

.select-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

.select {
    background-color: #EDEDED;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    color: #000000;
}

.sum {
    margin-top: -15px;
    font-weight: bold;
    font-size: 36px;
    font-weight: 600;
    line-height: 58px;
}

.p-button.btn-pay,
.p-button.p-component.btn-pay:hover {
    border-radius: 6px;
    background-color: var(--main-color);
    color: #FFFFFF;
    font-weight: bold;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    border: none;
}

.title-installment {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;

}


.card-bank h3 {
    font-weight: bold;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}

.card-bank {
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    padding: 20px;
}

.card-bank .tinkoff {
    top: -25px;
    left: calc(50% - 25px);
}

.card-bank .otp {
    top: -18px;
    left: calc(50% - 71px);
}

.p-button.p-component.btn-month,
.p-button.p-component.btn-month:hover {
    width: 110px;
    height: 40px;
    background-color: #EDEDED;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    border-radius: 6px;
    border: none;
}

button.p-button.p-component.btn-month.active {
    background-color: var(--main-color);
    color: #FFFFFF;
    border: none;
}

.buttons {
    max-width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
}

.btn-apply {
    border-radius: 6px;
    border: none;
    height: 40px;
}

.btn-apply.yellow,
.p-button.p-component.btn-apply.yellow:hover {
    background-color: #FCE527;
    color: #000000;
    border: none;
}

.btn-apply.green,
.p-button.p-component.btn-apply.green:hover {
    background-color: #016438;
    color: #FFFFFF;
}

.red button.p-button.p-component {
    background-color: #FC6D6D;
}

.gray button.p-button.p-component {
    background-color: #D9D9D9;
}

.p-divider.p-divider-horizontal:before {
    border-top: 4px solid #D9D9D9;
    top: 45%
}

.color .p-divider.p-divider-horizontal:before {
    border-top: 4px solid #FC6D6D;
}

.p-divider.p-divider-horizontal {
    margin: 0;
}

button.p-button.p-component.point {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0;
    border: 2px solid #FFFFFF;
}

.label-month {
    font-family: 'Medium Bold';
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
}

.phones p,
.phones h5 {
    font-weight: bold;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;

}

.made_in {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: #FC6D6D;

}

.links a {
    font-weight: bold;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #000000;
    text-decoration: underline;

}

.rights {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    opacity: 0.5;
}

.name {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
}

.requisites {
    font-weight: bold;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
}

.label {

    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    color: #000000;
    opacity: 0.5;
}

.label span {
    font-weight: 700;
}

.sum-payment {
    font-family: 'Medium Bold' !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
}


@media (max-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}

@media (max-width: 1200px) {
    .container {
        max-width: 960px;
    }
}



@media (max-width: 992px) {
    .container {
        max-width: 720px
    }

}

@media (max-width: 768px) {
    .container {
        max-width: 540px
    }

    .App {
        background-image: none;
    }
}

@media (max-width:576px) {
    .container {
        max-width: 440px
    }

}

.tabs .p-tabview .p-tabview-panels {
    padding: 1rem 0 0;
}

button.p-button.p-component.flex.p-button-rounded.p-button-sm.p-button-outlined.p-button-secondary.p-button-icon-only {
    position: absolute;
    right: -1%;
    top: -2%;
}