.comment {
    background-color: #F5F5F5;
    border: none;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    margin-top: 4px;
}

.comments-container {
    width: 100%;
    border: 1px solid #EDEDED;
    background: #fff;
    border-radius: 6px;
}

.comments-container.forms {
    height: calc(100vh - 230px);
}

.comments-container.no-forms {
    height: calc(100vh - 120px);
}

.comments {
    overflow-y: auto;
    padding: 0px 12px;
    height: 100%;
}

.p-scrollpanel.custom .p-scrollpanel-content {
    padding-right: 30px;
}

.p-scrollpanel-content::-webkit-scrollbar,
.comments::-webkit-scrollbar {
    width: 0;
}


.comments-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: var(--main-color);
    margin-bottom: 20px;
}

.comment-header {
    display: flex;
    justify-content: space-between;
}

.comment-author {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: var(--main-color);
    margin-bottom: 10px;
}

.comment-text {
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    margin-bottom: 10px;
    word-break: break-all
}

.comment-img-container {
    margin-right: 20px;
}

.CustomAvatar-image {
    border-radius: 50%;
    margin-bottom: 6px;
}

.comment-date {
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    opacity: 0.5
}

.p-button.reply,
.p-button.reply:hover {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-decoration-line: underline;
    color: var(--main-color);
    background-color: transparent;
    padding: 0;
    border: none;
    box-shadow: none;
    outline: none;
    margin-bottom: 10px;
}

.comment-admin {
    border-radius: 6px;
    background-color: #000000;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: #FFFFFF;
}

.write-comment-input,
.user-data {
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
}

.write-comment-input {
    background-color: #FFFFFF;
    border: none;
}

.user-data {
    background-color: #F5F5F5;
    border: 1px solid #EDEDED;
}

.p-inputtext.write-comment-input:enabled:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.send-comment-btn,
.send-comment-btn:hover {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.join-chat-btn,
.p-button.join-chat-btn:hover {
    height: 40px;
    border-radius: 6px;
    background-color: var(--main-color);
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    width: 100%;
    margin-bottom: 12px;
}

.next-comment-btn,
.p-button.next-comment-btn:hover {
    font-size: 14px;
    font-weight: 600;
    color: var(--main-color);
    background-color: transparent;
    padding: 0;
    border: none;
    box-shadow: none;
    outline: none;
    text-decoration: underline;
    margin-top: 15px;
}

.comments-title-container {
    padding: 10px 18px;
    border-radius: 6px;
    border-bottom: 1px solid #EDEDED
}

.write-comment {
    border-top: 1px solid #EDEDED;
    border-radius: 6px;
    padding: 0px 20px;
    background-color: #FFFFFF;
}

.title-data {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding-left: 12px;
}

@media (max-width: 992px) {
    .comments-container {
        width: 100%;
        border: none;
        padding: 0;

    }

    .comments-container.forms {
        max-height: calc(100vh - 247px);
        height: auto;
    }

    .comments-container.no-forms {
        max-height: calc(100vh - 142px);
        height: auto;
    }


    .write-comment-container {
        height: auto;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: #EDEDED;
        align-items: flex-start;
        width: 100%;
        padding-bottom: 46px;

    }


    .write-comment {
        border-radius: 0;
        background-color: #FFFFFF;
        height: 50px;
        align-items: center;
        border-top: 1px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9
    }

    .user-data,
    .p-inputtext.p-component.user-data:hover {
        border-radius: 0px;
        background-color: #FFFFFF;
        border: 1px solid #D9D9D9;
        height: 50px;
    }

    .join-chat-btn,
    .p-button.join-chat-btn:hover {
        border-radius: 20px;
    }
}