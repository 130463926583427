.timer-container {
    width: 100%;
}

.timer-block {
    background-color: #000000;
    border-radius: 6px;
    padding: 25px 20px;
    width: 100%;
}

.timer-block-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    color: #FFFFFF;
}

.timer-block-data {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: var(--main-color);
}

.p-button.timer-btn,
.p-button.p-component.timer-btn:hover {
    background-color: #000000;
    border-radius: 20px;
    color: #ffffff;
    border: none;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--main-color);
}

.time {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: var(--main-color);
}

@media (max-width: 993px) {
    .timer-container {
        margin-top: 11px;
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
}