@import '../../styles/variables.scss';

.tabs {
    // margin-top: 20px;
    width: 100%;
    padding: 10px 20px;
    border-left: 1px solid #ededed;
    border-right: 1px solid #ededed;
    background: #fff;
    border-radius: 6px;

    @media (max-width: 992px) {
        width: calc(100% - 20px);
        border: 1px solid #ededed;
        margin: 10px;

        .profit {
            background: #d9d9d9 !important;
        }

        .tab-booking-form {
            input {
                border: 1px solid #d9d9d9 !important;
                padding: 13px 20px;
                background: #fff !important;
            }
        }
    }

    &-nav {
        background: #fff;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;

        div {
            width: 300px;
            text-align: center;
            font-size: 24px;

            span {
                line-height: 29px;
            }

            h2 {
                font-size: 24px;
                line-height: 29px;
                color: #000;
            }
        }

        button.p-button {
            box-shadow: none;

            &:hover {
                opacity: 0.2;
            }
        }

        span {
            color: var(--main-color);
            font-size: 24px;
            font-weight: bold;
        }
    }

    &-content {
        .p-radiobutton .p-radiobutton-box.p-highlight {
            border-color: var(--main-color) !important;
            background: var(--main-color);
            box-shadow: none !important;
        }

        .p-radiobutton .p-radiobutton-box {
            &:hover {
                box-shadow: none !important;
                border: none !important;
                background: var(--main-color) !important;
            }
        }

        // Отключение стандартных переключателей табов
        .p-tabview-nav {
            display: none;
        }

        // ТАБ
        .tab {
            font-family: 'Gilroy', sans-serif;
            font-size: 14px;
            position: relative;

            .startDay {
                font-size: 20px;
                padding: 15px 0px;
                font-weight: 600;
            }

            .newPrice {
                color: var(--main-color);
                font-weight: 600;
                font-size: 36px;
                margin-right: 15px;
            }

            .discountProduct {
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
            }

            .oldPrice {
                text-decoration: line-through;
                font-size: 16px;
                font-weight: 300;
                margin-bottom: 10px;
            }

            .profit {
                text-align: center;
                width: 140px;
                height: 20px;
                border-radius: 5px;
                background: rgb(235, 235, 235);
                font-weight: 300;
                font-size: 12px;
                line-height: 20px;
                opacity: 0.8;
            }

            p {
                line-height: 20px;
                font-size: 14px;
                font-weight: 300;

                &.select-title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 22px;
                }

                &.sum {
                    margin-top: -15px;
                    font-weight: bold;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 58px;
                }

                &.pay-text {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    color: #777777;
                }

                &.sum-payment {
                    font-family: 'Medium Bold';
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 20px;
                }

                &.otp-text {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    color: #777777;
                    height: 40px;
                }

                b {
                    font-weight: 300;
                }
            }

            .title-description {
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
            }

            h4 {
                color: var(--main-color);
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 600;
            }



            &-content {

                &__li,
                &__title {
                    color: var(--main-color);
                    font-weight: bold;
                }

                &__title {
                    display: block;
                }

                &__li {
                    // height: 8px;
                    // width: 8px;
                    // background-color: var(--main-color);
                    // border-radius: 50%;
                    // display: inline-block;
                    margin-right: 0.5em;
                }
            }

            &-payment {
                font-size: 14px;
                font-weight: 300;
                display: flex;
                flex-wrap: wrap;
            }

            &-booking-form {
                .field {
                    height: 50px;
                }

                input {
                    font-family: 'Gilroy', sans-serif;
                    font-size: 14px;
                    font-weight: 300;
                    border-radius: 20px;
                    border: 1px solid #D9D9D9;
                    padding: 16px 23px;
                    background: #FFFFFF;

                    &:focus {
                        box-shadow: inset 0px 0px 4px var(--main-color);
                        border: none;
                    }

                    &:hover {
                        border: 1px solid #D9D9D9;
                    }
                }

                .phone {
                    position: relative;

                    select.PhoneInputCountrySelect {
                        display: none;
                    }

                    .PhoneInputCountryIcon {
                        width: 20px;
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    input.PhoneInputInput {
                        font-family: 'Gilroy', sans-serif;
                        width: 100%;
                        font-size: 14px;
                        font-weight: 300;

                        &:focus {
                            border: none;
                            outline: none;
                            box-shadow: inset 0px 0px 5px var(--main-color) !important;
                        }
                    }
                }

                button.p-button {
                    font-weight: 600;
                    border: none;
                    border-radius: 20px;
                    background: var(--main-color);
                    cursor: pointer;


                    &:hover {
                        box-shadow: 0px 10px 25px 0px #28282840 inset !important;
                    }

                    &:focus {
                        box-shadow: 0px 10px 25px 0px #28282840 inset !important;
                    }

                    .time {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 20px;
                        color: #FFFFFF;

                    }
                }



            }

            .hide-form_btn {
                color: #D9D9D9;
                background-color: transparent;
                font-family: Gilroy;
                font-size: 10px;
                font-weight: 600;
                line-height: 12px;
                border: none;
                cursor: pointer;
                width: 50%;
                margin-bottom: 10px;
            }
        }
    }
}