@import '../../styles/variables.scss';

.days {
    padding: 10px 20px;
    background: #ededed;
    border-radius: 6px;
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 768px) {
        padding: 0;
        background: #fff;
        width: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        justify-content: space-between;

        .day {
            background: #ededed;
            border: none;
            border-radius: 12px;
            // flex-direction: row !important;
            justify-content: space-between !important;
            min-width: 305px !important;
            height: 60px !important;
            margin-right: 10px;
            padding: 7px 34px !important;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media (max-width: 993px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .day {
        &-active {
            cursor: pointer;
            color: var(--main-color);
            // color: #cc8e73;
        }

        &-one {
            color: #000;
            justify-content: flex-start;
            width: 100%;
            cursor: default;
        }

        &-future {
            cursor: auto;
        }

        &-past {
            cursor: pointer;
            color: #000;

            &-unavailable {
                cursor: auto;
                color: rgb(199, 194, 194);
            }
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 35%;
        border-right: 1px solid #fff;
        padding: 0 15px;

        h3 {
            font-size: 16px;
            line-height: 20px;
        }

        span {
            font-size: 12px;
            line-height: 20px;
        }

        &:last-child {
            border-right: none;
        }
    }
}

.select-days {
    width: 100%;
    height: 50px;
    border-radius: 20px;

    .selected-day {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        color: var(--main-color);
    }

    .selected-title {
        font-size: 14px;
        font-weight: 300;
        line-height: 17px;
        color: #000;
    }

    @media (max-width: 993px) {
        /*  margin-right: 12px;
        margin-left: 12px; */
        max-width: 100vw;
        background-color: #F5F5F5;
    }

}

.select-day-container {
    @media (max-width: 993px) {
        padding-left: 12px;
        padding-right: 12px;
    }

}

.day {
    &-active {
        cursor: pointer;
        color: var(--main-color);

        &:hover {
            background-color: #F5F5F5;
        }

        // color: #cc8e73;
    }

    &-one {
        color: #000;
        justify-content: flex-start;
        width: 100%;
        cursor: default;
    }

    &-future {
        cursor: auto;
    }

    &-past {
        cursor: pointer;
        color: #000;

        &-unavailable {
            cursor: auto;
            color: rgb(199, 194, 194);
        }
    }

    display: flex;
    justify-content: center;
    width: 100%;
    border-right: 1px solid #fff;
    padding: 12px 20px;

    h3 {
        font-size: 16px;
        line-height: 20px;
    }

    span {
        font-size: 12px;
        line-height: 20px;
    }

}

.p-dropdown {
    &:not(.p-disabled) {
        &.p-focus {
            box-shadow: none;
            ;
            border-color: #D9D9D9;
        }

        &:hover {
            border-color: #D9D9D9;
        }
    }


}

.p-dropdown-panel {
    .p-dropdown-items {
        .p-dropdown-item {
            padding: 0;

            &.p-highlight {
                background-color: #F5F5F5;
            }

            &:not(.p-highlight) {
                &:not(.p-disabled) {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }

        }
    }
}